import React, { lazy } from 'react'
import { Link } from 'react-router-dom';
import "./banner.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import wcl_ipl from "./wcl-website.webp";
import wcl_ipl_phone from "./wcl-website-phone.webp";

import background4 from "./ban_5.webp";
import background_mob4 from "./bm-5.webp";

import neev_banner from "./neev_banner.webp";
import neev_banner_mob from "./neev_banner_mob.webp";

import ipl_banner from "./ipl-web-banner.webp";
import ipl_banner_mob from "./ipl-mobile-banner.webp";

import background5 from "./ban_6.webp";
import background5_mob from "./ban-2.webp";

import background1 from "./ban_2.webp";
import background1_mob from "./bm-2.webp";

import background2 from "./ban_3.webp";
import background2_mob from "./bm-3.webp";

import background3 from "./ban_4.webp";
import background3_mob from "./bm-4.webp"

// import new2bg from "./ind_sa.webp";
// import new2_mob_bg from "./ind_sa_mobile.webp"

//const new2bg = lazy(() => import("./wonder_superstar_ban2.webp"));
//const new2_mob_bg = lazy(() => import("./mobile-superstar.webp"));

//const new2bg = lazy(() => import("./ind_sa.webp"));
//const new2_mob_bg = lazy(() => import("./ind_sa_mobile.webp"));

const Banner = () => {
  const isMobile = window.innerWidth <= 768;

  const banner_img_1 = isMobile ? wcl_ipl_phone : wcl_ipl;
  const back_img_1 = isMobile ? background_mob4 : background4;
  const back_img_2 = isMobile ? neev_banner_mob : neev_banner;
  const ipl_banner_img = isMobile ? ipl_banner_mob : ipl_banner;
  const back_img_3 = isMobile ? background5_mob : background5;
  const back_img_4 = isMobile ? background1_mob : background1;
  const back_img_5 = isMobile ? background2_mob : background2;
  const back_img_6 = isMobile ? background3_mob : background3;

  return (
    <>
      <div className="home_slider">
        <Swiper
          slidesPerView={1}
          loop={true}
          modules={[Autoplay, Pagination, Navigation]}
          autoplay={{ delay: 5000 }}
          speed={1500}
          pagination={{
            clickable: true,
          }}
          className="parent">

          <SwiperSlide
            className='each-slide'
          >
            <a
              href="https://wonderticket.co.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="hero-section"
                style={{
                  backgroundImage: `url(${banner_img_1})`,
                }}
              >
                {/* <div className="ipl-banner-button">
                <button className='to-hover' > <Link to="/tata-ipl-consumer-t&c">Know More</Link></button>
              </div> */}
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <div className="hero-section"
              style={{
                backgroundImage: `url(${ipl_banner_img})`,
              }}
            >
              <div className="ipl-banner-button">
                <button className='to-hover' > <Link to="/tata-ipl-consumer-t&c">Know More</Link></button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <div className="hero-section hero-section-5"
              style={{
                backgroundImage: `url(${back_img_1})`,
              }}
            >

            </div>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <a
              href="https://theneev.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="hero-section"
                style={{
                  backgroundImage: `url(${back_img_2})`,
                }}
              >
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <div className="hero-section hero-section-1"
              style={{
                backgroundImage: `url(${back_img_3})`,
              }}
            >
              <div className="inner-text innertext-new">
                <h1>Marvels Of Wonder</h1>
                <button className='to-hover' > <Link to="/marvels-of-wonder">Click To See More</Link></button>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <div className="hero-section hero-section-2"
              style={{
                backgroundImage: `url(${back_img_4})`,
              }}
            >
              <div className="inner-text innertext-5">
                <h2>Plants,</h2>
                <p>where perfection takes a concrete shape.</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <div className="hero-section hero-section-3"
              style={{
                backgroundImage: `url(${back_img_5})`,
              }}
            >
              <div className="inner-text innertext-2">
                <h2>Every fleet,</h2>
                <p>a journey of celebration.</p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className='each-slide'
          >
            <div className="hero-section hero-section-4"
              style={{
                backgroundImage: `url(${back_img_6})`,
              }}
            >
              <div className="inner-text innertext-6">
                <h2>Technology,</h2>
                <p>that inspires sublime creations</p>
              </div>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>
    </>
  )
}

export default Banner
